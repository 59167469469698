const superfishArgs = ((window, $) => {
	// const init = function() {
	// console.log('superfish');
	$('.js-superfish').superfish({
		delay: 100, // 0.1 second delay on mouseout
		animation: { opacity: 'show', height: 'show' }, // fade-in and slide-down animation
		dropShadows: false,
		onBeforeShow() {
			if (this.is('.menu-primary>li.menu-item-full-navigation>ul')) {
				$(this).css({ display: 'grid' });
			}
		},
	});
	// };

	// return {
	// 	init,
	// };
})(window, jQuery);

export default superfishArgs;
