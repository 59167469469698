const languageDropdown = ((window, $) => {
	const init = () => {
		const langSwitch = $('.js-lang-switch'),
			currentLang = langSwitch.find('li.current-lang');
		currentLang.on('click', function(event) {
            event.stopPropagation(); 
            $(this).toggleClass('active');
		});
		changeActiveLanguage(langSwitch);
		langSwitch.on('click', function(event) {
			event.stopPropagation();
			$(this).toggleClass('active');
		});
	};

	const changeActiveLanguage = (langSwitch) => {
		const index = langSwitch.find('li.current-lang'),
			langSwitchUL = langSwitch.find('ul');
		index.detach();
		index.prependTo(langSwitchUL);
	};

	return {
		init,
	};
})(window, jQuery);

export default languageDropdown;
