/**
 * Sticky Header.
 *
 * @package Neher;
 * @author  Valentin Zmaranda
 * @license GPL-2.0+
 */

const searchFormBlur = ((window, $) => {
	const init = () => {
		const $body = $('body');
		// const searchForm = $('.search-form');
		const searchFormButton = $('[rel="open-button"]');
		const modalWindow = $('[rel="modal-search"]');
		searchFormButton.on('click', () => {
			$('.search-form-input').focus(function() {
				$(this).val('');
			});
			$body.toggleClass('blur compensate-for-scrollbar');
			modalWindow.toggleClass('fade-in');
		});
		modalWindow.on('click', (e) => {
			// console.log('1 ----', $(e.target).is('input'));
			// console.log('2 ----', $(e.target).is('button'));
			// console.log('2 ----', $(e.target).is('form'));
			// console.log(
			// 	'3 ----',
			// 	e.target.getAttribute('rel') !== 'open-button'
			// );
			// return false;
			if ($(e.target).is('input')) {
				return false;
			}
			// if (
			// 	// !!$(e.target).hasClass('search-form-submit') &&
			// 	!!$(e.target).is('input') &&
			// 	e.target.getAttribute('rel') === 'modal-search'
			// ) {
			console.log('pass');
			modalWindow.removeClass('fade-in');
			$body.removeClass('blur compensate-for-scrollbar');
			// }
		});
		// $('.search-form-input').on('blur', () => {
		// 	modalWindow.removeClass('fade-in');
		// 	$body.removeClass('blur compensate-for-scrollbar');
		// });
	};

	return {
		init,
	};
})(window, jQuery);

export default searchFormBlur;
