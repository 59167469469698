/**
 * External dependencies
 */
// eslint-disable-next-line no-unused-vars
import $ from 'jquery';

/**
 * Internal dependencies
 */
import './inc/responsive-menus';
import stickyHeader from './inc/sticky-header';
import searchFormBlur from './inc/search-form';
import languageDropdown from './inc/language-dropdown';
import './inc/superfish-args';


$(window).on('load', languageDropdown.init);
$(window).on('load', stickyHeader.init);
$(window).on('load', searchFormBlur.init);

//close mobile menu o click outside
$(window).on('load', function() {
	document.body.addEventListener('click', function(e) {
		if (!this.classList.contains('nav-open')) {
			return;
		}

		if (
			e.target.nodeName === 'BUTTON' &&
			e.target.classList.contains('sub-menu-toggle')
		) {
			return;
		}
		if (
			e.target.nodeName === 'BUTTON' &&
			e.target.classList.contains('menu-toggle')
		) {
			return;
		}

		if (
			e.target.nodeName === 'SPAN' &&
			e.target.offsetParent.nodeName === 'BUTTON'
		) {
			return;
		}

		if (e.target.offsetParent.nodeName === 'HEADER') {
			return;
		}
		document.querySelector('.menu-toggle').click();
	});
});


$(document).ready(function() {
	let initializer = false;
let heroInitializer = false;
const hero = $('.hero');
const header = $('.site-header');

const linksBox = $('.hero-links');
const linksBoxTopPosition = linksBox.css('top');

let heroLinksAdjustment = 0;



if(hero.length > 0){ 
	$(window).on('scroll', function() {
			const heroFromTop = hero.offset().top;
		const distanceFromTop = $('.site-container').offset().top;
		const scroll = $(window).scrollTop();
		const blurElement = scroll / 50;
		$('.hero-cover').css({
			filter: `blur(${blurElement}px)`,
		});
		if (hero.find('.hero-links').length > 0) {
			if (scroll >= heroFromTop - 87) {
				if (heroInitializer === false) {
					const headerHeight = header.outerHeight(true);
	
					hero.addClass('is-fixed with-hero-links');
	
					linksBox.css({
						top: Math.floor(headerHeight - heroLinksAdjustment),
					});
	
					heroInitializer = true;
				}
			} else {
				linksBox.css({ top: linksBoxTopPosition });
				hero.removeClass('is-fixed');
				heroInitializer = false;
			}
		}
	
		if (scroll >= distanceFromTop + 10) {
			if (initializer === false) {
				$('body').addClass('is-scroll');
	
				initializer = true;
			}
		} else if (scroll < distanceFromTop + 10) {
			$('body').removeClass('is-scroll');
	
			initializer = false;
		}
	});
}
});
