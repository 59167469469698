/**
 * Sticky Header.
 *
 * @package Neher;
 * @author  Valentin Zmaranda
 * @license GPL-2.0+
 */

const stickyHeader = ((window, $) => {
	/**
	 * Adjust site inner margin top to compensate for sticky header height.
	 *
	 * @since 2.6.0
	 */

	// eslint-disable-next-line @wordpress/no-unused-vars-before-return,no-unused-vars
	// const siteHeader = $('.site-header');
	// const headerHeight = document.querySelector('.logo-wrapper').offsetHeight;
	const siteInner = $('.site-inner');
	const $body = $('body');
	const offsetAdd =
		$body.hasClass('has-hero') || $body.hasClass('has-featured-image')
			? 300
			: 100;

	let initializer = false;
	// let siteInnerMarginTop = 0;
	const mq = window.matchMedia('(min-width: 320px)');
	let widthChange;
	let windowWidth;
	/* JavaScript Media Queries */
	if (matchMedia) {
		widthChange = (mediaQuery) => {
			return mediaQuery.matches;
		};
		windowWidth = widthChange(mq);
	}

	const fixedWithPrimaryNav = () => {
		if (windowWidth) {
			// const siteHeaderHeight = siteHeader.outerHeight(true);
			// siteInner.css({ marginTop: siteHeaderHeight });
			// console.log('siteHeaderHeight', siteHeaderHeight);

			const distanceFromTop = siteInner.offset().top;
			// console.log('distanceFromTop', distanceFromTop);

			$(window).on('scroll', function() {
				const sticky = $('header.site-header'),
					scroll = $(window).scrollTop();
				// console.warn('distanceFromTop - 300', distanceFromTop - 300);
				if (scroll >= distanceFromTop - offsetAdd) {
					// console.log('scroll', scroll);

					if (initializer === false) {
						sticky.addClass('scroll');
						$body.addClass('is-scroll');

						// sticky
						// 	.hide('slow', function() {
						// 		const $header = $(this);
						// 		$header.addClass('scroll');
						// 		setTimeout(function() {
						// 			$header.fadeTo(500, 1);
						// 		}, 320);
						// 	})
						// 	.finish();

						// siteInner.css({ marginTop: siteHeaderHeight });
						initializer = true;
					}
				} else if (scroll < distanceFromTop - offsetAdd) {
					// console.log('else', siteInnerMarginTop);
					$body.removeClass('is-scroll');
					sticky.removeClass('scroll');
					siteInner.removeAttr('style');
					// siteInner.css('margin-top', 0);
					initializer = false;
				}
			});
		}
	};

	const _moveContentBelowFixedHeader = () => {
		fixedWithPrimaryNav();
	};
	const init = () => {
		_moveContentBelowFixedHeader();
		$(window).resize(() => {
			_moveContentBelowFixedHeader();
		});
	};

	return {
		init,
	};
})(window, jQuery);

export default stickyHeader;
